import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IDodajIzvodManuelnoCommand, IDodajTransakcijuManuelnoCommand, ITekuciRacunDto, TekuciRacunDto } from '@kodit/core/data-api';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IzvodiService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  showStrankaSearch$ = new BehaviorSubject<number>(null);

  set setShowStrankaSearch(value: number) {
    this.showStrankaSearch$.next(value);
  }

  get getShowStrankaSearch() {
    return this.showStrankaSearch$.asObservable();
  }

  reloadUplataTable$ = new BehaviorSubject<boolean>(false);

  set setReloadUplataTable(value: boolean) {
    this.reloadUplataTable$.next(value);
  }

  get getReloadUplataTable() {
    return this.reloadUplataTable$.asObservable();
  }

  izvodForm: IzvodForm;

  getIzvodForm(){
    return (this.izvodForm = this._getIzvodFormGroup());
  }

  private _getIzvodFormGroup() : FormGroupTypeSafe<IDodajIzvodManuelnoCommand>{
    return this._fb.group<IDodajIzvodManuelnoCommand>({
      id: new FormControl(null),
      broj: new FormControl(null, {
        validators: [Validators.required],
      }),
      datumIzdavanja: new FormControl(null, {
        validators: [Validators.required],
      }),
      stanjeTrenutnogIzvoda: new FormControl(null, {
        validators: [Validators.required],
      }),
      stanjePrethodnogIzvoda: new FormControl(null, {
        validators: [Validators.required],
      }),
      tekuciRacunId: new FormControl(null, {
        validators: [Validators.required],
      }),
      valutaId:  new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  transakcijaForm: TransakcijaForm;

  getTransakcijaForm(){
    return (this.transakcijaForm = this._getTransakcijaFormGroup());
  }

  private _getTransakcijaFormGroup() : FormGroupTypeSafe<IDodajTransakcijuManuelnoCommand>{
    return this._fb.group<IDodajTransakcijuManuelnoCommand>({
      id: new FormControl(null),
      smerKnjizenja: new FormControl(null, {
        validators: [Validators.required],
      }),
      drugaStranaNaziv: new FormControl(null, {
        validators: [Validators.required],
      }),
      drugaStranaRacun: new FormControl(null, {
        validators: [Validators.required],
      }),
      iznos: new FormControl(null, {
        validators: [Validators.required],
      }),
      sifraPlacanja: new FormControl(null),
      svrhaPlacanja: new FormControl(null, {
        validators: [Validators.required],
      }),
      pozivNaBroj: new FormControl(null, {
        validators: [Validators.required],
      }),
      datumKnjizenja: new FormControl(null, {
        validators: [Validators.required],
      }),
      datumValute: new FormControl(null, {
        validators: [Validators.required],
      }),
      izvodId: new FormControl(null, {
        validators: [Validators.required],
      }),
      drugaStrana: new FormControl()
    });
  }

}

export declare type IzvodForm = FormGroupTypeSafe<IDodajIzvodManuelnoCommand>;
export declare type TransakcijaForm = FormGroupTypeSafe<IDodajTransakcijuManuelnoCommand>;
