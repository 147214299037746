import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IzvodDto, SaltanjeIzvodaDto } from '@kodit/core/data-api';
import { Store } from '@ngrx/store';
import { clearFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { SelectItem } from 'primeng/api';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'kodit-obrada-izvoda-page',
  templateUrl: './obrada-izvoda-page.component.html',
  styleUrls: ['./obrada-izvoda-page.component.scss'],
})
export class ObradaIzvodaPageComponent implements OnInit, OnDestroy {
  /** Props */
  izvod: IzvodDto;
  tabeleOptions: SelectItem[];
  tabeleSelectedOption: number = 1;
  jeProcreditBanka: boolean;
  jeRucnoUneto: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _breadcrumbService: BreadcrumbService,
    private _storage: Store
  ) {
    _route.params.subscribe(() => {
      this.izvod = this._route.snapshot.data.izvod;
      this.jeRucnoUneto = this.izvod.jeRucnoUneto;
      this.jeProcreditBanka = this.izvod.nazivBanke.includes('ProCredit');
      // this._clearFilters();
      this._route.snapshot.data.jeUplata = this.tabeleSelectedOption === 1;
      this._setBreadcrumb();
    });
  }
  private _clearFilters() {
    this._storage.dispatch(
      clearFilter({
        key: 'UPLATE'
      })
    );

    this._storage.dispatch(
      clearFilter({
        key: 'ISPLATE',
      })
    );
  }

  ngOnInit(): void {
    this.tabeleOptions = [
      { value: 1, label: 'Uplate' },
      { value: 2, label: 'Isplate' },
    ];
    this._clearFilters();
  }

  onSaltanjeIzvoda(izvod: SaltanjeIzvodaDto) {
    this._router.navigate(['banka', 'izvodi', 'obrada-izvoda', izvod.izvodId]);
  }

  private _setBreadcrumb() {
    this._breadcrumbService.set(
      '@ObradaIzvoda',
      `Obrada izvoda ${this.izvod.broj}`
    );
  }

  ngOnDestroy(): void {
      this._clearFilters();
  }
}
