<div class="p-d-flex p-flex-column p-fluid">
    <form [formGroup]="form">

        <kodit-field
            fieldLabel="Broj izvoda"
            fieldLabelFor="broj-izvoda"
        >
            <kodit-number-input
            [formCtrl]="form.controls.broj"
            formCtrlId="broj-izvoda"
            [ctrlOnlyIntegers]="true">
            </kodit-number-input>
        </kodit-field>

        <kodit-field
            fieldLabel="Datum"
        >
            <kodit-calendar
            ctrlPlaceholder="Datum upisa"
            formCtrlId="datumUpisa"
            [formCtrl]="form.controls.datumIzdavanja"
            ></kodit-calendar>
        </kodit-field>

        <kodit-field 
            fieldLabel="Tekući račun" 
            fieldLabelFor="tekuci-racun"
        >
            <kodit-tekuci-racun-dropdown
                [tekuciRacunCtrl]="form.controls.tekuciRacunId"
                tekuciRacunIdCtrl="tekuci-racun"
            ></kodit-tekuci-racun-dropdown>
        </kodit-field>

        <kodit-field 
            fieldLabel="Valuta" 
            fieldLabelFor="valuta"
        >
            <kodit-valuta-dropdown
                [valutaCtrl]="form.controls.valutaId"
            ></kodit-valuta-dropdown>
        </kodit-field>

        <kodit-field
            fieldLabel="Početno stanje"
            fieldLabelFor="pocetno-stanje"
        >
            <kodit-number-input
            [formCtrl]="form.controls.stanjePrethodnogIzvoda"
            formCtrlId="pocetno-stanje"
            >
            </kodit-number-input>
        </kodit-field> 
        <kodit-field
            fieldLabel="Krajnje stanje"
            fieldLabelFor="krajnje-stanje"
        >
            <kodit-number-input
            [formCtrl]="form.controls.stanjeTrenutnogIzvoda"
            formCtrlId="krajnje-stanje"
            >
            </kodit-number-input>
        </kodit-field>   
    
    </form>
</div>
  
