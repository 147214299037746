<div class="p-d-flex p-flex-column p-fluid">
    <form [formGroup]="form">
      
      <div class="input-container">
        <kodit-field
          fieldLabel="Platni račun - PRIMALAC / PLATILAC"
          fieldLabelFor="druga-strana-racun"
        >
        <div class="input-button-wrapper">
          <kodit-text-input
            [formCtrl]="form.controls.drugaStranaRacun"
            formCtrlId="druga-strana-racun"
          ></kodit-text-input>
          <button
            type="button"
            pButton
            pRipple
            icon="fa-solid fa-magnifying-glass"
            (click)="getStranka()"
            [disabled]="!form.controls.drugaStranaRacun.value"
          ></button>
        </div>
        <div style="margin-top: 10px;">
          <p-message
          *ngIf="showMessage"
          severity="info"
          text="Tekući račun nije vezan ni za jedno pravno lice u sistemu Narodne Banke Srbije. Polje *Naziv PRIMALAC / PLATILAC* morate popuniti ručno."
        ></p-message></div>
        </kodit-field>
      </div>

      <kodit-field
              fieldLabel="Naziv - PRIMALAC / PLATILAC"
              fieldLabelFor="druga-strana-naziv"
          >
          <kodit-text-input
            [formCtrl]="form.controls.drugaStranaNaziv"
            formCtrlId="druga-strana-naziv"
            ctrlPlaceholder="Pretražite po tekućem/platnom računu"
            [isDisabled]="!isSearchClicked"
        ></kodit-text-input>
      </kodit-field>
          
        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">

          <kodit-field
            fieldLabel="Šifra plaćanja"
            fieldLabelFor="sifra-placanja"
            class="flex-20"
          >
            <kodit-sifra-placanja-dropdown
              [sifrePlacanjaCtrl]="form.controls.sifraPlacanja"
              (onSifrePlacanjaChanged)="onSifrePlacanjaChanged($event)"
            >
            </kodit-sifra-placanja-dropdown>
          </kodit-field>
      
          <kodit-field
            fieldLabel="Opis"
            fieldLabelFor="svrha-placanja"
            class="flex-50"
          >
            <kodit-text-input
              [formCtrl]="form.controls.svrhaPlacanja"
              formCtrlId="svrha-placanja"
            ></kodit-text-input>
          </kodit-field>
        </div>
      <kodit-field
        fieldLabel="Poziv na broj"
        fieldLabelFor="poziv-na-broj"
      >
      <kodit-text-input
        [formCtrl]="form.controls.pozivNaBroj"
        formCtrlId="poziv-na-broj"
      ></kodit-text-input>
    </kodit-field>

        <kodit-field
          fieldLabel="Iznos"
          fieldLabelFor="uplacen-iznos"
        >
          <kodit-number-input
            [formCtrl]="form.controls.iznos"
            formCtrlId="uplacen-iznos"
            >
          </kodit-number-input>
        </kodit-field>  

        <div class="p-d-flex p-flex-column p-flex-md-row gap-12 p-fluid">
          <kodit-field
              fieldLabel="Datum prijema"
              class="flex-50"
          >
              <kodit-calendar
              ctrlPlaceholder="Datum prijema"
              formCtrlId="datum-knjizenja"
              [formCtrl]="form.controls.datumKnjizenja"
              ></kodit-calendar>
          </kodit-field>

          <kodit-field
              fieldLabel="Datum izvršenja"
              class="flex-50"
          >
              <kodit-calendar
              ctrlPlaceholder="Datum izvršenja"
              formCtrlId="datum-valute"
              [formCtrl]="form.controls.datumValute"
              ></kodit-calendar>
          </kodit-field>
        </div>
      </form>
</div>
  
