import { Component, OnInit } from '@angular/core';
import {
  IVezaniRacunDto,
  RacuniClient,
  RemoveVezaniRacunCommand,
  TipRacuna,
} from '@kodit/core/data-api';
import { AlertService, LocationService } from '@kodit/core/services';
import { Subscription } from 'rxjs';
import { RacunInfoService } from '../racun-info/racun-info.service';

@Component({
  selector: 'kodit-povezani-racuni-info',
  templateUrl: './povezani-racuni-info.component.html',
  styleUrls: ['./povezani-racuni-info.component.scss'],
})
export class PovezaniRacuniInfoComponent implements OnInit {
  private _subs: Subscription = new Subscription();

  id: number;
  vezaniRacuni: IVezaniRacunDto[] = [];
  jeMoguceOdvezati: boolean = false;
  radniNalogTip: TipRacuna = TipRacuna.RADNI_NALOG;
  racunTip: TipRacuna = TipRacuna.EMPTY;

  constructor(
    private _locationService: LocationService,
    private _racunInfoService: RacunInfoService,
    private _racunClient: RacuniClient,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this._racunInfoService.getRacunInfoDto.subscribe((res) => {
        this.vezaniRacuni = res.vezaniRacuniDto;
        this.id = res.id;
        this.racunTip = res.tip;
      })
    );
  }

  handleGoToRacun(racun: IVezaniRacunDto) {
    this._locationService
      .routeToRacunKartica(racun.racunId, racun.tip, racun.vrsta)
      .then();
  }

  handleRemoveRacun(index: number) {
    if (
      this.racunTip == TipRacuna.PREDRACUN &&
      this.vezaniRacuni[index].tip == TipRacuna.AVANSNI_RACUN
    ) {
      return this._alertService.addWarnMsg(
        'Nije moguće razvezati avansni račun od predračuna'
      );
    } else if (
      this.racunTip == TipRacuna.AVANSNI_RACUN &&
      this.vezaniRacuni[index].tip == TipRacuna.PREDRACUN
    ) {
      return this._alertService.addWarnMsg(
        'Nije moguće razvezati predračun od avansnog računa'
      );
    }

    this._subs.add(
      this._racunClient
        .removeVezaniRacun({
          povezanChildRacunId: this.vezaniRacuni[index].racunId,
          tipPoveznogRacuna: this.vezaniRacuni[index].tip,
          id: this.id,
        } as RemoveVezaniRacunCommand)
        .subscribe((res) => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.messages[0]);
            location.reload();
          } else {
            this._alertService.addWarnMsg(res.messages[0]);
          }
        })
    );

    this.vezaniRacuni.splice(index, 1);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
