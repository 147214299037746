import { Component, OnDestroy, OnInit } from '@angular/core';
import { DodajTransakcijuManuelnoCommand, IDodajTransakcijuManuelnoCommand, ISifraPlacanjaDto, IStrankaRacunaDto, IzvodiClient, SifraPlacanjaDto, StrankaClient, StrankaRacunaDto } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { IzvodiService } from '../izvodi.service';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, ButtonService, SharedService } from '@kodit/core/services';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';

@Component({
  selector: 'kodit-unos-transakcije-form',
  templateUrl: './unos-transakcije-form.component.html',
  styleUrls: ['./unos-transakcije-form.component.scss']
})
export class UnosTransakcijeFormComponent implements OnInit, OnDestroy{

  form!: FormGroupTypeSafe<IDodajTransakcijuManuelnoCommand>;

  isSearchClicked = false;
  showMessage = false;

  private _subs: Subscription = new Subscription();

  constructor(
    private _service: IzvodiService,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _btnService: ButtonService,
    private _sharedService: SharedService,
    private _client: IzvodiClient,
    private _alertService: AlertService,
    public _dialogConfig: DynamicDialogConfig,
    public _strankaCLient: StrankaClient
  ) { }

  ngOnInit(): void {
    this.form = this._service.getTransakcijaForm();

    this.form.controls.smerKnjizenja.setValue(this._dialogConfig.data.smerKnjizenja);
    this.form.controls.smerKnjizenja.updateValueAndValidity();
    this.form.controls.izvodId.setValue(this._dialogConfig.data.izvodId);
    this.form.controls.izvodId.updateValueAndValidity();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {

    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._btnService.disableButton();

    this._sharedService.displayLoadingScreen(true, "Unos transakcije");
    this._subs.add(
      this._client
        .dodajTransakcijuManuelno(
          this.form.value as DodajTransakcijuManuelnoCommand
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(
             res.messages[0]
            );
            this._dialogRef.close(true);
        })
      );
  }

  getStranka(){
    this.showMessage = false;
    this._sharedService.displayLoadingScreen(true, "Pronalaženje stranke");
    this._subs.add(
      this._strankaCLient
        .getStrankaOdTekucegRacuna(this.form.controls.drugaStranaRacun.value)
        .subscribe({
          next: (strankaDto) => {
            this.form.controls.drugaStrana.setValue(strankaDto);
            this.form.controls.drugaStranaNaziv.setValue(strankaDto.naziv);
            this.form.controls.smerKnjizenja.updateValueAndValidity();
          },
          error: (error) => {
            this.showMessage = true;
          }
        })
    );
    this.isSearchClicked = true;
  }

  onSifrePlacanjaChanged(sifraPlacanja: ISifraPlacanjaDto) {
    this.form.controls.svrhaPlacanja.setValue(sifraPlacanja.opis);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();  
  }

}
