import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Moduli */
import { CoreSharedUiModule } from '@kodit/core/shared-ui';

/** PrimeNg */
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { SelectButtonModule } from 'primeng/selectbutton';

/** Components */
import { AdresaFormComponent } from './adresa/adresa-form/adresa-form.component';
import { DrzavaDropdownComponent } from './adresa/ui/drzava-dropdown/drzava-dropdown.component';
import { RacunTableComponent } from './racun/racun-table/racun-table.component';
import { TipMagacinaFormComponent } from './tip-magacina/tip-magacina-form/tip-magacina-form.component';
import { LagerTableComponent } from './lager/lager-table/lager-table.component';
import { LagerTransakcijeTableComponent } from './lager/lager-transakcije-table/lager-transakcije-table.component';
import { TableFilterFormComponent } from './table/table-filter-form/table-filter-form.component';
import { UplataTableComponent } from './izvod/uplata-table/uplata-table.component';
import { ObradaTransakcijeIzvodaComponent } from './izvod/obrada-transakcije-izvoda-form/obrada-transakcije-izvoda-form.component';
import { NeplaceniRacunTableComponent } from './racun/neplaceni-racun-table/neplaceni-racun-table.component';
import { IsplataTableComponent } from './izvod/isplata-table/isplata-table.component';
import { LagerTransakcijeFormComponent } from './lager/lager-transakcije-form/lager-transakcije-form.component';
import { ObradaPocetnogStanjaComponent } from './izvod/obrada-pocetnog-stanja/obrada-pocetnog-stanja.component';
import { PosaljiElektronskiFormComponent } from './racun/posalji-elektronski-form/posalji-elektronski-form.component';
import { LagerFilterComponent } from './lager/lager-filter/lager-filter.component';
import { CancelIzlazniRacunFormComponent } from './racun/cancel-izlazni-racun-form/cancel-izlazni-racun-form.component';
import { StornoIzlazniRacunFormComponent } from './racun/storno-izlazni-racun-form/storno-izlazni-racun-form.component';
import { PosaljiNaSefFormComponent } from './racun/posalji-na-sef-form/posalji-na-sef-form.component';
import { PocetnoStanjeRacunTableComponent } from './izvod/pocetno-stanje-racun-table/pocetno-stanje-racun-table.component';
import { UplateIsplateFilterComponent } from './izvod/uplate-isplate-filter/uplate-isplate-filter.component';
import { RacunSummaryComponent } from './summary/racun-summary/racun-summary.component';
import { RacunVanValuteSummaryComponent } from './summary/racun-van-valute-summary/racun-van-valute-summary.component';
import { LagerTransakcijeFilterComponent } from './lager/lager-transakcije-filter/lager-transakcije-filter.component';
import { KompenzacijaTableComponent } from './racun/kompenzacija-table/kompenzacija-table.component';
import { RacunUploadFormComponent } from './racun/racun-upload-form/racun-upload-form';
import { RacunFilterOpsegDropdownComponent } from './racun/racun-filter/filter-components/racun-filter-opseg-dropdown/racun-filter-opseg-dropdown.component';
import { RacunFilterPeriodDropdownComponent } from './racun/racun-filter/filter-components/racun-filter-period-dropdown/racun-filter-period-dropdown.component';
import { RacunFilterComponent } from './racun/racun-filter/racun-filter.component';
import { PeriodMesecDropdownComponent } from './racun/racun-filter/filter-components/period-mesec-dropdown/period-mesec-dropdown.component';
import { RacunExportFormComponent } from './racun/racun-export-form/racun-export-form.component';
import { PosaljiIzjavuZaOdbitakPdvFormComponent } from './racun/posalji-izjavu-za-odbitak-pdv-form/posalji-izjavu-za-odbitak-pdv-form/posalji-izjavu-za-odbitak-pdv-form.component';
import { OdabirPaketaFormComponent } from './registracija/odabir-paketa-form/odabir-paketa-form.component';
import { CheckboxModule } from 'primeng/checkbox';
import { SliderModule } from 'primeng/slider';
import { EvidentiranjePredracunaOptionDialogComponent } from './izvod/obrada-transakcije-izvoda-form/evidentiranje-predracuna-option-dialog/evidentiranje-predracuna-option-dialog.component';
import { UplatnicaFormComponent } from './racun/uplatnica-form/uplatnica-form.component';
import { KlasifikacijaDokumentaFormComponent } from './racun/klasifikacija-dokumenta-form/klasifikacija-dokumenta-form.component';
import { KlasifikacijaRacunaDropdownComponent } from 'libs/core/shared-ui/src/lib/ui/klasifikacija-racuna-dropdown/klasifikacija-racuna-dropdown.component';
import { PravoNaPdvOdbitakDropdownComponent } from 'libs/core/shared-ui/src/lib/ui/pravo-na-pdv-odbitak-dropdown/pravo-na-pdv-odbitak-dropdown.component';
import { UnosTransakcijeFormComponent } from './izvod/unos-transakcije-form/unos-transakcije-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreSharedUiModule,
    InputTextModule,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
    RadioButtonModule,
    MenuModule,
    MessagesModule,
    MessageModule,
    SelectButtonModule,
    CheckboxModule,
    SliderModule,
  ],
  declarations: [
    AdresaFormComponent,
    DrzavaDropdownComponent,
    RacunTableComponent,
    TipMagacinaFormComponent,
    LagerTableComponent,
    LagerTransakcijeTableComponent,
    RacunFilterComponent,
    TableFilterFormComponent,
    UplataTableComponent,
    ObradaTransakcijeIzvodaComponent,
    NeplaceniRacunTableComponent,
    IsplataTableComponent,
    LagerTransakcijeFormComponent,
    ObradaPocetnogStanjaComponent,
    PosaljiElektronskiFormComponent,
    LagerFilterComponent,
    CancelIzlazniRacunFormComponent,
    StornoIzlazniRacunFormComponent,
    PosaljiNaSefFormComponent,
    PocetnoStanjeRacunTableComponent,
    UplateIsplateFilterComponent,
    RacunSummaryComponent,
    RacunVanValuteSummaryComponent,
    RacunUploadFormComponent,
    LagerTransakcijeFilterComponent,
    KompenzacijaTableComponent,
    RacunFilterOpsegDropdownComponent,
    RacunFilterPeriodDropdownComponent,
    PeriodMesecDropdownComponent,
    RacunExportFormComponent,
    PosaljiIzjavuZaOdbitakPdvFormComponent,
    OdabirPaketaFormComponent,
    EvidentiranjePredracunaOptionDialogComponent,
    UplatnicaFormComponent,
    KlasifikacijaDokumentaFormComponent,
    UnosTransakcijeFormComponent
  ],
  exports: [
    AdresaFormComponent,
    RacunTableComponent,
    TipMagacinaFormComponent,
    LagerTableComponent,
    LagerTransakcijeTableComponent,
    TableFilterFormComponent,
    UplataTableComponent,
    ObradaTransakcijeIzvodaComponent,
    NeplaceniRacunTableComponent,
    IsplataTableComponent,
    RacunSummaryComponent,
    DrzavaDropdownComponent,
    RacunVanValuteSummaryComponent,
    KompenzacijaTableComponent,
    PeriodMesecDropdownComponent,
    OdabirPaketaFormComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreSharedSubformsModule {}
