import { Component, OnDestroy, OnInit } from '@angular/core';
import { DodajIzvodManuelnoCommand, IDodajIzvodManuelnoCommand, IzvodiClient } from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, ButtonService, SharedService } from '@kodit/core/services';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { IzvodiService } from 'libs/core/shared-subforms/src/lib/izvod/izvodi.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-dodaj-izvod-form',
  templateUrl: './dodaj-izvod-form.component.html',
  styleUrls: ['./dodaj-izvod-form.component.scss']
})
export class DodajIzvodFormComponent implements OnInit, OnDestroy {

  form!: FormGroupTypeSafe<IDodajIzvodManuelnoCommand>;

  private _subs: Subscription = new Subscription();


  constructor(
    private _service: IzvodiService,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _btnService: ButtonService,
    private _sharedService: SharedService,
    private _client: IzvodiClient,
    private _alertService: AlertService,

  ) { }

  ngOnInit(): void {

    this.form = this._service.getIzvodForm();
    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {

    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._btnService.disableButton();

    this._sharedService.displayLoadingScreen(true, "Unos izvoda");
    this._subs.add(
      this._client
        .dodajIzvodManuelno(
          this.form.value as DodajIzvodManuelnoCommand
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(
             res.messages[0]
            );
            this._dialogRef.close(true);
        })
      );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();  
  }

}
