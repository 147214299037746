import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

/** Modules */
import { RacunRoutingModule } from './moduli-racun-routing.module';
import { CoreSharedModule } from '@kodit/core/shared';
import { CoreSharedSubformsModule } from '@kodit/core/shared-subforms';
import { CoreModuliRobaModule } from '@kodit/core/moduli/roba';
import { CoreModuliUslugaModule } from '@kodit/core/moduli/usluga';

/** Components */
import { AkcijeRacunaComponent } from './subforms/akcije-racuna/akcije-racuna.component';
import { RacunFormContainerComponent } from './subforms/racun-form-container/racun-form-container.component';
import { BrojRacunaInputComponent } from './ui/broj-racuna-input/broj-racuna-input.component';
import { NapomeneRacunaComponent } from './subforms/napomene-racuna/napomene-racuna.component';
import { OsnovneInformacijeRacunaComponent } from './subforms/osnovne-informacije-racuna/osnovne-informacije-racuna.component';
import { OznakeRacunaComponent } from './subforms/oznake-racuna/oznake-racuna.component';
import { StavkeRacunaTableComponent } from './subforms/stavke-racuna-table/stavke-racuna-table.component';
import { RokVazenjaInputComponent } from './ui/rok-vazenja-input/rok-vazenja-input.component';
import { OtpremnicaFormComponent } from './otpremnica/otpremnica-form/otpremnica-form.component';
import { OtpremnicaPageComponent } from './otpremnica/otpremnica-page/otpremnica-page.component';
import { PreuzeoInputComponent } from './otpremnica/ui/preuzeo-input/preuzeo-input.component';
import { RezultatPretragePredmetaStavkeComponent } from './subforms/rezultat-pretrage-predmeta-stavke/rezultat-pretrage-predmeta-stavke.component';
import { PredmetStavkeRacunaInputComponent } from './ui/predmet-stavke-racuna-input/predmet-stavke-racuna-input.component';
import { PodaciUplateFormComponent } from './subforms/podaci-uplate-form/podaci-uplate-form.component';
import { RacunInfoComponent } from './info/racun-info/racun-info.component';
import { OsnovneInformacijeComponent } from './info/osnovne-informacije/osnovne-informacije.component';
import { UplatePoRacunuComponent } from './info/uplate-po-racunu/uplate-po-racunu.component';
import { IzmeneOsnovicePoRacunuComponent } from './info/izmene-osnovice-po-racunu/izmene-osnovice-po-racunu.component';
import { StornoRacunaComponent } from './info/storno-racuna/storno-racuna.component';
import { RelevantniDokumentiPoRacunuComponent } from './info/relevantni-dokumenti-po-racunu/relevantni-dokumenti-po-racunu.component';
import { IstorijatRacunaComponent } from './info/istorijat-racuna/istorijat-racuna.component';
import { OdobravanjaRacunaComponent } from './info/odobravanja-racuna/odobravanja-racuna.component';
import { IzlaznaFakturaFormComponent } from './izlazne-fakture/izlazna-faktura-form/izlazna-faktura-form.component';
import { UlaznaFakturaFormComponent } from './ulazne-fakture/ulazna-faktura-form/ulazna-faktura-form.component';
import { PredracunFormComponent } from './predracun/predracun-form/predracun-form.component';
import { UplatePoRacunuTableComponent } from './info/uplate-po-racunu-table/uplate-po-racunu-table.component';
import { StrankaRacunaComponent } from './ui/stranka-racuna/stranka-racuna.component';
import { StrankaRacunaAutocompleteComponent } from './ui/stranka-racuna/stranka-racuna-autocomplete/stranka-racuna-autocomplete.component';
import { AvansniRacunFormComponent } from './avansni-racun/avansni-racun-form/avansni-racun-form.component';
import { AvansneUplateRacunaCardComponent } from './subforms/avansne-uplate-racuna-card/avansne-uplate-racuna-card.component';
import { AvansnaUplataRacunaTableComponent } from './subforms/avansne-uplate-racuna-card/avansna-uplata-racuna-table/avansna-uplata-racuna-table.component';
import { UkupanIznosTableComponent } from './subforms/ukupan-iznos-table/ukupan-iznos-table.component';
import { IskoriscenostAvansnogRacunaComponent } from './info/iskoriscenost-avansnog-racuna/iskoriscenost-avansnog-racuna.component';
import { AvansniRacunTableComponent } from './avansni-racun/avansni-racun-table/avansni-racun-table.component';
import { NeobradjenRacunFormComponent } from './neobradjeni-racuni/neobradjen-racun-form/neobradjen-racun-form.component';
import { NeobradjenRacunObradaStavkeCardComponent } from './neobradjeni-racuni/neobradjen-racun-obrada-stavke-card/neobradjen-racun-obrada-stavke-card.component';
import { OdobravanjeOdbacivanjeRacunaFormComponent } from './neobradjeni-racuni/odobravanje-odbacivanje-racuna-form/odobravanje-odbacivanje-racuna-form.component';
import { NeobradjenSefRacunTableComponent } from './neobradjeni-racuni/neobradjen-sef-racun-table/neobradjen-sef-racun-table.component';
import { DokumentOSmanjenjuFormComponent } from './knjizno-odobrenje/dokument-o-smanjenju-form/dokument-o-smanjenju-form.component';
import { IzlazniRacunPageComponent } from './izlazni-racun-page/izlazni-racun-page.component';
import { UlazniRacunPageComponent } from './ulazni-racun-page/ulazni-racun-page.component';
import { OdabirIzvorneFaktureComponent } from './subforms/odabir-izvorne-fakture/odabir-izvorne-fakture.component';
import { PovezaniRacuniComponent } from './subforms/povezani-racuni/povezani-racuni.component';
import { DokumentOPovecanjuFormComponent } from './knjizno-zaduzenje/dokument-o-povecanju-form/dokument-o-povecanju-form.component';
import { RezultatPretrageKursComponent } from './subforms/rezultat-pretrage-predmeta-stavke/rezultat-pretrage-kurs/rezultat-pretrage-kurs.component';
import { PovezaniRacuniInfoComponent } from './info/povezani-racuni-info/povezani-racuni-info.component';
import { PodaciUplateDevizniComponent } from './subforms/podaci-uplate-devizni/podaci-uplate-devizni.component';
import { NeobradjeniSefRacuniFilterComponent } from './neobradjeni-racuni/neobradjeni-sef-racuni-filter/neobradjeni-sef-racuni-filter.component';
import { KompenzacijaFormComponent } from './kompenzacije/kompenzacija-form/kompenzacija-form.component';
import { StrankaKompenzacijaFormComponent } from './kompenzacije/subforms/stranka-kompenzacija-form/stranka-kompenzacija-form.component';
import { MojaFirmaKompenzacijaFormComponent } from './kompenzacije/subforms/moja-firma-kompenzacija-form/moja-firma-kompenzacija-form.component';
import { ObavezeKompenzacijaTableComponent } from './kompenzacije/subforms/obaveze-kompenzacija-table/obaveze-kompenzacija-table.component';
import { RacunKompenzacijaAutocompleteComponent } from './kompenzacije/subforms/racun-kompenzacija-autocomplete/racun-kompenzacija-autocomplete.component';
import { IzlaznaFakturaFileuploadComponent } from './subforms/izlazna-faktura-fileupload/izlazna-faktura-fileupload.component';
import { RadniNalogFormComponent } from './radni-nalog/radni-nalog-form/radni-nalog-form.component';
import { RadniNalogPageComponent } from './radni-nalog/radni-nalog-page/radni-nalog-page.component';
import { RokDatepickerComponent } from './radni-nalog/ui/rok-datepicker/rok-datepicker.component';
import { VrstaRadnogNalogaDropdownComponent } from './radni-nalog/ui/vrsta-radnog-naloga-dropdown/vrsta-radnog-naloga-dropdown.component';
import { CoreSharedUiModule } from '@kodit/core/shared-ui';
import { InformacijaIsporukeComponent } from './subforms/informacija-isporuke/informacija-isporuke.component';
import { InformacijaIsporukeStrankeDropdownComponent } from './subforms/informacija-isporuke/informacija-isporuke-stranke-dropdown/informacija-isporuke-stranke-dropdown.component';
import { InformacijaIsporukeStrankeCheckboxComponent } from './subforms/informacija-isporuke/informacija-isporuke-stranke-checkbox/informacija-isporuke-stranke-checkbox.component';
import { NeobradjenRacunFilesFormComponent } from './neobradjeni-racuni/neobradjen-racun-files-form/neobradjen-racun-files-form';
import { UnosBrojaOptionComponent } from './ui/unos-broja-option/unos-broja-option';
import { IzmenaOsnoviceComponent } from './subforms/izmena-osnovice/izmena-osnovice.component';
import { RadniNalogTableComponent } from './radni-nalog/radni-nalog-table/radni-nalog-table.component';
import { SekcijaZaRacunanjeTezineComponent } from './subforms/sekcija-za-racunanje-tezine/sekcija-za-racunanje-tezine.component';
import { EmailPorukePoRacunuComponent } from './info/email-poruke-po-racunu/email-poruke-po-racunu.component';
import { JoyrideModule } from 'ngx-joyride';
import { SinhronizacijaFormComponent } from './neobradjeni-racuni/neobradjen-sef-racun-table/sinhronizacija-form/sinhronizacija-form.component';
import { OdobravanjeOznakeFormComponent } from './neobradjeni-racuni/odobravanje-odbacivanje-racuna-form/odobravanje-oznake-form/odobravanje-oznake-form.component';
import { CreateOdobrenRacunFormComponent } from './neobradjeni-racuni/create-odobren-racun-form/create-odobren-racun-form.component';
import { RazlogStorniranjaOdbijanjaDialogComponent } from './neobradjeni-racuni/razlog-storniranja-odbijanja-dialog/razlog-storniranja-odbijanja-dialog.component';
import { MenuModule } from 'primeng/menu';
import { NosiocJavneNabavkeComponent } from './ui/stranka-racuna/nosioc-javne-nabavke/nosioc-javne-nabavke.component';
import { RelevantniDokumentPreviewComponent } from './info/relevantni-dokument-preview/relevantni-dokument-preview.component';
import { NapomenaKompenzacijeComponent } from './kompenzacije/ui/napomena-kompenzacije/napomena-kompenzacije.component';
import { OznakeRacunaInfoComponent } from './info/oznake-racuna-info/oznake-racuna-info.component';
import { AddOznakaFormComponent } from './info/oznake-racuna-info/add-oznaka-form/add-oznaka-form.component';
import { RemoveOznakaDialogComponent } from './info/oznake-racuna-info/remove-oznaka-dialog/remove-oznaka-dialog.component';
import { HasAvansnaUplataDialogComponent } from './izlazne-fakture/has-avansna-uplata-dialog/has-avansna-uplata-dialog.component';
import { AddPovezaniRacunFormComponent } from './info/osnovne-informacije/add-povezani-racun-form/add-povezani-racun-form.component';

@NgModule({
  imports: [
    RacunRoutingModule,
    CoreSharedModule,
    CoreSharedSubformsModule,
    CoreModuliRobaModule,
    CoreModuliUslugaModule,
    CoreSharedUiModule,
    JoyrideModule,
    MenuModule,
  ],
  declarations: [
    AkcijeRacunaComponent,
    RacunFormContainerComponent,
    BrojRacunaInputComponent,
    NapomeneRacunaComponent,
    OsnovneInformacijeRacunaComponent,
    OznakeRacunaComponent,
    StavkeRacunaTableComponent,
    RokVazenjaInputComponent,
    OtpremnicaFormComponent,
    OtpremnicaPageComponent,
    PreuzeoInputComponent,
    RezultatPretragePredmetaStavkeComponent,
    PredmetStavkeRacunaInputComponent,
    PodaciUplateFormComponent,
    RacunInfoComponent,
    OsnovneInformacijeComponent,
    UplatePoRacunuComponent,
    IzmeneOsnovicePoRacunuComponent,
    StornoRacunaComponent,
    RelevantniDokumentiPoRacunuComponent,
    IstorijatRacunaComponent,
    OdobravanjaRacunaComponent,
    IzlaznaFakturaFormComponent,
    UlaznaFakturaFormComponent,
    PredracunFormComponent,
    UplatePoRacunuTableComponent,
    StrankaRacunaComponent,
    StrankaRacunaAutocompleteComponent,
    AvansniRacunFormComponent,
    AvansneUplateRacunaCardComponent,
    AvansnaUplataRacunaTableComponent,
    UkupanIznosTableComponent,
    IskoriscenostAvansnogRacunaComponent,
    AvansniRacunTableComponent,
    NeobradjenRacunFormComponent,
    NeobradjenRacunObradaStavkeCardComponent,
    OdobravanjeOdbacivanjeRacunaFormComponent,
    NeobradjenSefRacunTableComponent,
    DokumentOSmanjenjuFormComponent,
    IzlazniRacunPageComponent,
    UlazniRacunPageComponent,
    OdabirIzvorneFaktureComponent,
    PovezaniRacuniComponent,
    DokumentOPovecanjuFormComponent,
    RezultatPretrageKursComponent,
    PovezaniRacuniInfoComponent,
    PodaciUplateDevizniComponent,
    NeobradjeniSefRacuniFilterComponent,
    IzlaznaFakturaFileuploadComponent,
    RadniNalogFormComponent,
    RadniNalogPageComponent,
    RokDatepickerComponent,
    VrstaRadnogNalogaDropdownComponent,
    InformacijaIsporukeComponent,
    InformacijaIsporukeStrankeDropdownComponent,
    InformacijaIsporukeStrankeCheckboxComponent,
    NeobradjenRacunFilesFormComponent,
    UnosBrojaOptionComponent,
    IzmenaOsnoviceComponent,
    KompenzacijaFormComponent,
    StrankaKompenzacijaFormComponent,
    MojaFirmaKompenzacijaFormComponent,
    ObavezeKompenzacijaTableComponent,
    RacunKompenzacijaAutocompleteComponent,
    RadniNalogTableComponent,
    SekcijaZaRacunanjeTezineComponent,
    EmailPorukePoRacunuComponent,
    SinhronizacijaFormComponent,
    OdobravanjeOznakeFormComponent,
    CreateOdobrenRacunFormComponent,
    RazlogStorniranjaOdbijanjaDialogComponent,
    NosiocJavneNabavkeComponent,
    AddOznakaFormComponent,
    NapomenaKompenzacijeComponent,
    RelevantniDokumentPreviewComponent,
    OznakeRacunaInfoComponent,
    RemoveOznakaDialogComponent,
    HasAvansnaUplataDialogComponent,
    AddPovezaniRacunFormComponent,
  ],
  exports: [StrankaRacunaAutocompleteComponent, StrankaRacunaComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliRacunModule { }
