import { Injectable } from '@angular/core';
import {
  IDokumentForPdvDto,
  IPdvEvidencijaPojedinacna39Dto,
  IPdvEvidencijaPojedinacnaDto,
  IStrankaForPdvDto,
  IVezaniRacunDto,
  PdvEvidencijaOsnovOdabiraDokumenta,
  PdvEvidencijaTipDokumenta,
  PdvEvidencijaTipPovezaneFakture,
  PdvPeriod,
  TipRacuna,
  VezaniRacunDto,
} from '@kodit/core/data-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { PdvEvidencijaService } from '../pdv-evidencija.service';
import { PdvEvidencijaPojedinacnaForm39Service } from './pdv-evidencija-pojedinacna39-form.service';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { VezaniRacunFormService } from '@kodit/core/form-definitions';

@Injectable({
  providedIn: 'root',
})
export class PdvEvidencijaPojedinacnaService {
  constructor(
    private _pdvService: PdvEvidencijaService,
    private _vezaniRacunFS: VezaniRacunFormService,
    private _fb: FormBuilder
  ) {}

  pdvForm$ = new BehaviorSubject<PdvForm>(null);
  pdv39Form$ = new BehaviorSubject<Pdv39Form>(null);
  povezaniDokumenti$ = new BehaviorSubject<VezaniRacunDto[]>([]);
  isIsporuka$ = new BehaviorSubject<boolean>(true);
  tipDokumentaSubject$ = new BehaviorSubject<PdvEvidencijaTipDokumenta>(
    PdvEvidencijaTipDokumenta.FAKTURA
  );
  isAvansni$ = new BehaviorSubject<boolean>(true);
  osnovOdabira$ = new BehaviorSubject<PdvEvidencijaOsnovOdabiraDokumenta>(
    PdvEvidencijaOsnovOdabiraDokumenta.PROMET
  );
  isInterniZaAvans$ = new BehaviorSubject<boolean>(false);
  _pdvPeriod : PdvPeriod = PdvPeriod.JANUAR;

  set setPdvForm(model: PdvForm) {
    this.pdvForm$.next(model);
  }

  get getPdvFormObject(): PdvForm {
    return this.pdvForm$.getValue();
  }

  set setPdv39Form(model: Pdv39Form) {
    this.pdv39Form$.next(model);
  }

  get getPdv39FormObject(): Pdv39Form {
    return this.pdv39Form$.getValue();
  }

  set setIsIsporuka(isIsporuka: boolean) {
    this.isIsporuka$.next(isIsporuka);
    if (!isIsporuka) {
      this.setIsAvansni = false;
      this.setIsInterniZaAvans = true;
      this.setTipDokumentaSubject =
        PdvEvidencijaTipDokumenta.INTERNI_RACUN_ZA_PROMET_STRANOG_LICA;
      return;
    }
    this.setIsAvansni = true;
    this.setIsInterniZaAvans = false;
    this.setTipDokumentaSubject = PdvEvidencijaTipDokumenta.FAKTURA;
  }

  get getIsIsporuka(): boolean {
    return this.isIsporuka$.getValue();
  }

  get getIsIsporukaObservable(): Observable<boolean> {
    return this.isIsporuka$.asObservable();
  }

  set setTipDokumentaSubject(model: PdvEvidencijaTipDokumenta) {
    this.tipDokumentaSubject$.next(model);
  }

  get getTipDokumentaSubject(): PdvEvidencijaTipDokumenta {
    return this.tipDokumentaSubject$.getValue();
  }

  get getTipDokumentaSubjectObservable(): Observable<PdvEvidencijaTipDokumenta> {
    return this.tipDokumentaSubject$.asObservable();
  }

  set setIsAvansni(model: boolean) {
    this.isAvansni$.next(model);
  }

  get getIsAvansni(): boolean {
    return this.isAvansni$.getValue();
  }

  get getIsAvansniObservable(): Observable<boolean> {
    return this.isAvansni$.asObservable();
  }

  set setOsnovOdabira(model: PdvEvidencijaOsnovOdabiraDokumenta) {
    this.osnovOdabira$.next(model);
  }

  get getOsnovOdabira(): PdvEvidencijaOsnovOdabiraDokumenta {
    return this.osnovOdabira$.getValue();
  }

  get getOsnovOdabiraObservable(): Observable<PdvEvidencijaOsnovOdabiraDokumenta> {
    return this.osnovOdabira$.asObservable();
  }

  set setIsInterniZaAvans(model: boolean) {
    this.isInterniZaAvans$.next(model);
  }

  get getIsInterniZaAvans(): boolean {
    return this.isInterniZaAvans$.getValue();
  }

  get getIsInterniZaAvansObservable(): Observable<boolean> {
    return this.isInterniZaAvans$.asObservable();
  }

  // get getUkupanIznos(): number {
  //   return this.getPdvFormObject?.value.ukupanIznos ?? 0;
  // }

  set setUkupanIznos(ukupanIznos: number | undefined) {
    if (!ukupanIznos) {
      return;
    }
    this.getPdvFormObject?.controls.ukupanIznos?.patchValue(ukupanIznos);
  }

  set setUkupanIznosNaknade(ukupanIznosNaknade: number | undefined) {
    if (!ukupanIznosNaknade) {
      return;
    }

    if (this.getPdvFormObject) {
      this.getPdvFormObject?.controls.ukupanIznosNaknade?.patchValue(
        ukupanIznosNaknade
      );
      return;
    }
    this.getPdv39FormObject?.controls.ukupanIznosNaknade?.patchValue(
      ukupanIznosNaknade
    );
  }

  set setOsnovica10(osnovica10: number | undefined) {
    if (osnovica10 === null || osnovica10 === undefined || isNaN(osnovica10)) {
      return;
    }
    if (this.getPdvFormObject) {
      this.getPdvFormObject?.controls.osnovica10?.patchValue(osnovica10);
      return;
    }
    this.getPdv39FormObject?.controls.osnovica10?.patchValue(osnovica10);
  }

  set setOsnovica20(osnovica20: number | undefined) {
    if (osnovica20 === null || osnovica20 === undefined || isNaN(osnovica20)) {
      return;
    }
    if (this.getPdvFormObject) {
      this.getPdvFormObject?.controls.osnovica20?.patchValue(osnovica20);
      return;
    }
    this.getPdv39FormObject?.controls.osnovica20?.patchValue(osnovica20);
  }

  set setObracunatPdv(obracunatPdv: number | undefined) {
    if (
      obracunatPdv === null ||
      obracunatPdv === undefined ||
      isNaN(obracunatPdv)
    ) {
      return;
    }

    if (this.getPdvFormObject) {
      this.getPdvFormObject?.controls.obracunatPdv?.patchValue(obracunatPdv);
      return;
    }
    this.getPdv39FormObject?.controls.obracunatPdv?.patchValue(obracunatPdv);
  }

  set setDatumPrometa(datumPrometa: Date | undefined) {
    if (!datumPrometa) {
      return;
    }
    this.getPdvFormObject?.controls.datumPrometa?.patchValue(datumPrometa);
  }

  set setDatumValute(datumValute: Date | undefined) {
    if (!datumValute) {
      return;
    }
    this.getPdvFormObject?.controls.datumValute?.patchValue(datumValute);
  }

  set setOpis(opis: string | undefined){
    this.getPdv39FormObject?.controls.opisPrometa10?.patchValue(opis);
  }

  set setGodina(godina: number | undefined){
    if(!godina){
      return;
    }

    this.getPdv39FormObject?.controls.godina?.patchValue(godina);
  }

  set setPdvPeriod(mesec: number | undefined){
    if(!mesec){
      return;
    }
      if (mesec < 0 || mesec > 11) {
        return;
      }
  
      switch (mesec) {
        case 0:
          this._pdvPeriod = PdvPeriod.JANUAR;
          break;
        case 1:
          this._pdvPeriod = PdvPeriod.FEBRUAR;
          break;
        case 2:
          this._pdvPeriod = PdvPeriod.MART;
          break;
        case 3:
          this._pdvPeriod = PdvPeriod.APRIL;
          break;
        case 4:
          this._pdvPeriod = PdvPeriod.MAJ;
          break;
        case 5:
          this._pdvPeriod = PdvPeriod.JUN;
          break;
        case 6:
          this._pdvPeriod = PdvPeriod.JUL;
          break;
        case 7:
          this._pdvPeriod = PdvPeriod.AVGUST;
          break;
        case 8:
          this._pdvPeriod = PdvPeriod.SEPTEMBAR;
          break;
        case 9:
          this._pdvPeriod = PdvPeriod.OKTOBAR;
          break;
        case 10:
          this._pdvPeriod = PdvPeriod.NOVEMBAR;
          break;
        case 11:
          this._pdvPeriod = PdvPeriod.DECEMBAR;
          break;
        default:
          return;
      }  
    this.getPdv39FormObject?.controls.period?.patchValue(this._pdvPeriod);
  }

  set setOpisZa10 (opis: string | undefined){
    this.getPdv39FormObject?.controls.opisPrometa10?.patchValue(opis);
  }

  set setOpisZa20 (opis: string | undefined){
    this.getPdv39FormObject?.controls.opisPrometa20?.patchValue(opis);
  }

  set setPovezaneAvansneFakture(racuni: IVezaniRacunDto[]){
    this.getPovezaniRacuniArray.clear();
    racuni.forEach((povezaniRacun: IVezaniRacunDto) => {
      this.getPovezaniRacuniArray.push(this._vezaniRacunFS.getFormGroup(povezaniRacun));
    });
    this.getPdv39FormObject?.controls.povezaniRacuni?.updateValueAndValidity();
  }
 
  get getPovezaniRacuniArray(): FormArray {
    return this.getPdv39FormObject?.controls.povezaniRacuni as FormArray
  }


  set setDatumAvansa(datumAvansa: Date | undefined | null) {
    if (!datumAvansa) {
      return;
    }

    this.getPdv39FormObject?.controls.datumAvansa?.patchValue(datumAvansa);
  }

  set setVremenskiPeriodPocetak(vremenskiPeriodPocetak: Date | undefined) {
    if (!vremenskiPeriodPocetak) {
      return;
    }
    this.getPdv39FormObject?.controls.vremenskiPeriodPocetak?.patchValue(
      vremenskiPeriodPocetak
    );
  }

  set setVremenskiPeriodZavrsetak(vremenskiPeriodZavrsetak: Date | undefined) {
    if (!vremenskiPeriodZavrsetak) {
      return;
    }
    this.getPdv39FormObject?.controls.vremenskiPeriodZavrsetak?.patchValue(
      vremenskiPeriodZavrsetak
    );
  }

  get getStranka(): StrankaForm {
    return this.getPdvFormObject?.getSafe<IStrankaForPdvDto>(
      (x) => x.stranka!
    ) as StrankaForm;
  }

  get getStranka39(): StrankaForm {
    return this.getPdv39FormObject?.getSafe<IStrankaForPdvDto>(
      (x) => x.stranka!
    ) as StrankaForm;
  }

  set setStranka(stranka: IStrankaForPdvDto | undefined) {
    if (!stranka) {
      this.getStranka.reset();
      return;
    }
    if (this.getPdvFormObject) {
      this.getStranka.patchValue(stranka);
      return;
    }
    // this.getStranka39.setControl(
    //   'pibOrJbkjs',
    //   new FormControl("", {})
    // );
    this.getStranka39.patchValue(stranka);
    this.getPdv39FormObject!.controls.stranka!.get('pibOrJbkjs')!.disable();
  }

  get getDokument(): DokumentForm {
    if (this.getPdvFormObject) {
      return this.getPdvFormObject?.getSafe<IDokumentForPdvDto>(
        (x) => x.dokument!
      ) as DokumentForm;
    }

    return this.getPdv39FormObject?.getSafe<IDokumentForPdvDto>(
      (x) => x.povezanaFaktura!
    ) as DokumentForm;
  }

  set setDokument(dokument: IDokumentForPdvDto | undefined) {
    if (!dokument) {
      this.getPdvFormObject?.controls.dokument?.reset();
      return;
    }
    if (this.getPdvFormObject) {
      this.getPdvFormObject?.controls.dokument?.patchValue(dokument);
    } else {
      this.getPdv39FormObject?.controls.povezanaFaktura?.patchValue(dokument);
    }
    this.setTipDokumenta = dokument.tip;
  }

  set setTipDokumenta(tip: TipRacuna | undefined) {
    switch (tip) {
      case TipRacuna.AVANSNI_RACUN:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.AVANSNA_FAKTURA
        );
        break;
      case TipRacuna.IZLAZNA_FAKTURA:
      case TipRacuna.ULAZNA_FAKTURA:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.FAKTURA
        );
        break;
      case TipRacuna.DOKUMENT_O_SMANJENJU:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.DOKUMENT_O_SMANJENJU
        );
        break;
      case TipRacuna.DOKUMENT_O_POVECANJU:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.DOKUMENT_O_POVECANJU
        );
        break;
      default:
        this.getPdvFormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipDokumenta.DRUGI_INTERNI_RACUN
        );
        break;
    }
  }

  set setTipPovezanogDokumenta(tip: TipRacuna | undefined) {
    switch (tip) {
      case TipRacuna.AVANSNI_RACUN:
        this.getPdv39FormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipPovezaneFakture.AVANSNA_FAKTURA
        );
        break;
      case TipRacuna.IZLAZNA_FAKTURA:
        this.getPdv39FormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipPovezaneFakture.FAKTURA
        );
        break;
      default:
        this.getPdv39FormObject?.controls.tip?.patchValue(
          PdvEvidencijaTipPovezaneFakture.VREMENSKI_PERIOD
        );
        break;
    }
  }

  /**
   * Cuvamo vrednost da li smo kliknuli na Sacuvaj formu kako bi mogli da validiramo (BrojDokumenta i Stranka)
   */

  formSaveClicked$ = new BehaviorSubject<boolean>(false);

  set setFormSaveClicked(value: boolean) {
    this.formSaveClicked$.next(value);
  }

  get getFormSaveClickedObject() {
    return this.formSaveClicked$.asObservable();
  }

  resetDokument() {
    if (this.getPdvFormObject) {
      this.getPdvFormObject?.controls.dokument?.reset();
    }
    this.getPdvFormObject?.controls.dokument?.reset();
  }

  resetStranka() {
    if (this.getPdvFormObject) {
      this.getPdvFormObject?.controls.stranka?.reset();
      return;
    }
    this.getPdv39FormObject?.controls.stranka?.reset();
  }

  resetForm() {
    this.setStranka = undefined;
    // Promet
    this.setUkupanIznos = 0;
    this.setOsnovica10 = 0;
    this.setOsnovica20 = 0;
    this.setObracunatPdv = 0;
    this.setTipDokumenta = TipRacuna.IZLAZNA_FAKTURA;
    this.resetDokument();
  }

  resetForm39() {
    this.resetDokument();
    this.resetStranka();
    this.setPdv39Form = null;
  }
}

export declare type PdvForm = FormGroupTypeSafe<IPdvEvidencijaPojedinacnaDto> | null;
export declare type Pdv39Form = FormGroupTypeSafe<IPdvEvidencijaPojedinacna39Dto> | null;
export declare type StrankaForm = FormGroupTypeSafe<IStrankaForPdvDto>;
export declare type DokumentForm = FormGroupTypeSafe<IDokumentForPdvDto>;
