import {Component, OnDestroy, OnInit} from '@angular/core';
import {InvoiceType, TransactionType, VrstaFiskalnogRacuna,} from '@kodit/core/data-api';
import {DynamicDialogConfig, DynamicDialogService,} from '@kodit/core/shared-ui';
import {DialogSize} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {Subscription} from 'rxjs';
import {
  OdabirKupcaFiskalnogRacunaDialogComponent
} from '../../dialogs/odabir-kupca-fiskalnog-racuna-dialog/odabir-kupca-fiskalnog-racuna-dialog.component';
import {
  ReferentniRacunFiskalnogRacunaDialogComponent
} from '../../dialogs/referentni-racun-fiskalnog-racuna-dialog/referentni-racun-fiskalnog-racuna-dialog.component';
import {
  ReklamniBlokFiskalnogRacunaDialogComponent
} from '../../dialogs/reklamni-blok-fiskalnog-racuna-dialog/reklamni-blok-fiskalnog-racuna-dialog.component';
import {
  VrstaFiskalnogRacunaDialogComponent
} from '../../dialogs/vrsta-fiskalnog-racuna-dialog/vrsta-fiskalnog-racuna-dialog.component';
import {FiskalniRacunService} from '../../fiskalni-racun.service';
import {
  EsirVremeFiskalnogRacunaDialogComponent
} from "../../dialogs/esir-vreme-fiskalnog-racuna-dialog/esir-vreme-fiskalnog-racuna-dialog.component";

export const vrstaFiskalnogDescriptionMap: Map<InvoiceType, string> = new Map<
  InvoiceType,
  string
>([
  [InvoiceType.NORMAL, 'Promet'],
  [InvoiceType.PROFORMA, 'Predračun'],
  [InvoiceType.TRAINING, 'Obuka'],
  [InvoiceType.ADVANCE, 'Avans'],
]);

@Component({
  selector: 'kodit-akcije-fiskalnog-racuna',
  templateUrl: './akcije-fiskalnog-racuna.component.html',
  styleUrls: ['./akcije-fiskalnog-racuna.component.scss'],
})
export class AkcijeFiskalnogRacunaComponent implements OnInit, OnDestroy {
  /** Subs */
  _subs: Subscription = new Subscription();

  /** Configuration */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  /** Props */
  vrstaRacunaSubtitle: string = 'Izaberi';
  kupacSubtitle: string = 'Izaberi';
  referentniRacunSubtitle: string = 'Izaberi';
  reklamniBlokSubtitle: string = 'Izaberi';

  /** Conditional props */
  shouldDisableButton = false;
  shouldDisplayEsirVreme = false;
  shouldDisplaySertifikate = true;

  constructor(
    private _dialogService: DynamicDialogService,
    private _fiskalniService: FiskalniRacunService
  ) {
  }

  ngOnInit(): void {
    this._setDialogConfig();
    this._subscriptions();

    // set podrazumevanu vrstu racuna
    this.vrstaRacunaSubtitle = vrstaFiskalnogDescriptionMap.get(
      InvoiceType.NORMAL
    )!;
  }

  openVrstaRacunaDialog() {
    if (this.shouldDisableButton) {
      return;
    }

    this.dialogConfig.header = 'Izbor vrste računa';
    this.dialogConfig.data = {
      vrstaRacunaSelected: this._getVrstaFiskalnogRacunaSelected(),
    };

    this._dialogService.open(
      VrstaFiskalnogRacunaDialogComponent,
      this.dialogConfig
    );
  }

  openEsirVremeDialog() {
    const dialogConfigEsir = new DynamicDialogConfig(DialogSize.SMALL);
    dialogConfigEsir.header = 'Unos esir vremena';
    dialogConfigEsir.maximisable = false;
    dialogConfigEsir.closable = false;

    const ref = this._dialogService.open(
      EsirVremeFiskalnogRacunaDialogComponent,
      dialogConfigEsir
    );
  }

  openOdabirKupcaDialog() {
    this.dialogConfig.header = 'Podaci o kupcu';
    this.dialogConfig.hideFooter = false;
    const ref = this._dialogService.open(
      OdabirKupcaFiskalnogRacunaDialogComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe((res: boolean) => {
        if (!res) {
          this._fiskalniService.getFormObject.controls.buyerIdentification?.patchValue(
            null
          );
          this._fiskalniService.getFormObject.controls.buyerId?.patchValue(
            null
          );
          this._fiskalniService.getFormObject.controls.buyerOptionalField?.patchValue(
            null
          );
          this._fiskalniService.getFormObject.controls.buyerCostCenterId?.patchValue(
            null
          );
        }
      })
    );
  }

  openReferentniRacunDialog() {
    if (this.shouldDisableButton) {
      return;
    }

    this.dialogConfig.header = 'Unos reference';
    this.dialogConfig.hideFooter = false;
    this.dialogConfig.closable = false;
    const ref = this._dialogService.open(
      ReferentniRacunFiskalnogRacunaDialogComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe((res: boolean) => {
        if (!res) {
          this._fiskalniService.resetReferentniRacun();
        }
      })
    );
  }

  openReklamniBlokDialog() {
    this.dialogConfig.header = 'Unos reklamnog bloka';
    this.dialogConfig.hideFooter = false;
    const ref = this._dialogService.open(
      ReklamniBlokFiskalnogRacunaDialogComponent,
      this.dialogConfig
    );

    this._subs.add(
      ref.onClose.subscribe((res) => {
        if (res) {
          this.reklamniBlokSubtitle = 'Dodato'
        } else {
          this.reklamniBlokSubtitle = 'Izaberi'
          this._fiskalniService.getFormObject.controls.journalOption?.reset();
        }
      })
    );
  }

  private _getVrstaFiskalnogRacunaSelected(): VrstaFiskalnogRacuna {
    const invoiceType = this._fiskalniService.getFormObject.controls
      .invoiceType!.value;
    const transactionType = this._fiskalniService.getFormObject.controls
      .transactionType!.value;

    if (
      invoiceType === InvoiceType.NORMAL &&
      transactionType === TransactionType.SALE
    ) {
      return VrstaFiskalnogRacuna.PROMET;
    }
    if (
      invoiceType === InvoiceType.NORMAL &&
      transactionType === TransactionType.REFUND
    ) {
      return VrstaFiskalnogRacuna.PROMET_REFUNDACIJA;
    }

    if (
      invoiceType === InvoiceType.ADVANCE &&
      transactionType === TransactionType.SALE
    ) {
      return VrstaFiskalnogRacuna.AVANS;
    }

    if (
      invoiceType === InvoiceType.ADVANCE &&
      transactionType === TransactionType.REFUND
    ) {
      return VrstaFiskalnogRacuna.AVANS_REFUNDACIJA;
    }

    if (
      invoiceType === InvoiceType.PROFORMA &&
      transactionType === TransactionType.SALE
    ) {
      return VrstaFiskalnogRacuna.PREDRACUN;
    }

    if (
      invoiceType === InvoiceType.PROFORMA &&
      transactionType === TransactionType.REFUND
    ) {
      return VrstaFiskalnogRacuna.PREDRACUN_REFUNDACIJA;
    }

    if (
      invoiceType === InvoiceType.TRAINING &&
      transactionType === TransactionType.SALE
    ) {
      return VrstaFiskalnogRacuna.OBUKA;
    }

    if (
      invoiceType === InvoiceType.TRAINING &&
      transactionType === TransactionType.REFUND
    ) {
      return VrstaFiskalnogRacuna.OBUKA_REFUNDACIJA;
    }

    return VrstaFiskalnogRacuna.PROMET;
  }

  private _subscriptions() {
    this._subs.add(
      this._fiskalniService.getFormObject.controls.buyerId?.valueChanges.subscribe(
        (res) => {
          this.kupacSubtitle = res!;
        }
      )
    );

    this._subs.add(
      this._fiskalniService.getFormObject.controls.referentniFiskalniRacunDto?.valueChanges.subscribe(
        (res) => {
          this.referentniRacunSubtitle = res?.referentDocumentNumber!;
        }
      )
    );

    this._subs.add(
      this._fiskalniService.getShouldBlockAkcijaButton.subscribe((res) => {
        this.shouldDisableButton = res;
      })
    );

    /** BS Subtitles za svaku akciju */
    this._subs.add(
      this._fiskalniService.getFormObject.controls.invoiceType?.valueChanges.subscribe(
        (res) => {
          this._setSubtitleZaSvakuAkciju(res);
          this._shouldDisplayUnosEsirVremena(res);
        }
      )
    );
  }

  private _setSubtitleZaSvakuAkciju(invoiceType? : InvoiceType){
    const refundacija =
      this._fiskalniService.getFormObject.controls.transactionType
        ?.value === TransactionType.REFUND
        ? ' refundacija'
        : '';
    vrstaFiskalnogDescriptionMap.forEach(
      (value: string, key: InvoiceType) => {
        if (invoiceType === key) {
          this.vrstaRacunaSubtitle = value + refundacija;
        }
      }
    );
  }

  private _shouldDisplayUnosEsirVremena(invoiceType? : InvoiceType){
    if(invoiceType !== InvoiceType.ADVANCE){
      this._fiskalniService.getFormObject.controls.dateAndTimeOfIssue?.reset();
    }

    this.shouldDisplayEsirVreme = invoiceType === InvoiceType.ADVANCE;
    if(this.shouldDisplayEsirVreme){
      this._fiskalniService.setEsirVreme = new Date();
      return;
    }
    this._fiskalniService.resetEsirVreme();
  }

  private _setDialogConfig() {
    this.dialogConfig.maximisable = false;
    this.dialogConfig.closable = true;
    this.dialogConfig.customSubmitButton = {
      icon: '',
      label: 'Prihvati',
      class: 'mw-90',
    };
    this.dialogConfig.customCancelButton = {
      icon: '',
      label: 'Otkaži',
      class: 'mw-90',
    };
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
