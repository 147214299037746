import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ISifraPlacanjaDto, SharedClient } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-sifra-placanja-dropdown',
  templateUrl: './sifra-placanja-dropdown.component.html',
  styleUrls: ['./sifra-placanja-dropdown.component.scss'],
})
export class SifraPlacanjaDropdownComponent implements OnInit, OnDestroy {
  /**Subs */
  private _sifraSub = new Subscription();

  /**Props */
  sifrePlacanja: ISifraPlacanjaDto[] = [];
  sifreOptions: {
    value: string;
    opis: string;
  }[] = [];

  // form ctrl to update data (sifra placanja) in edit faktura
  modelCtrl: FormGroup;

  /**I/O */
  @Input() sifrePlacanjaCtrl: FormControl;
  @Output() onSifrePlacanjaChanged = new EventEmitter<ISifraPlacanjaDto>();

  constructor(private _client: SharedClient, private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.modelCtrl = this._fb.group({
      sifra: new FormControl(this.sifrePlacanjaCtrl.value),
    });

    this._loadSifrePlacanja();
  }

  private _loadSifrePlacanja() {
    this._sifraSub.add(
      this._client.getSifrePlacanja().subscribe((value) => {
        this.sifrePlacanja = value.data.sifre;
        this.sifreOptions = value.data.sifre.map((v) => ({
          value: v.sifra,
          opis: v.opis,
        }));
      })
    );
  }

  handleOptionChange(event: any) {
    if (this.onSifrePlacanjaChanged) {
      const sifraPlacanja = this.sifrePlacanja.find(
        (d) => d.sifra === event.value
      );
      this.sifrePlacanjaCtrl.patchValue(sifraPlacanja.sifra);
      this.onSifrePlacanjaChanged.emit(sifraPlacanja);
    }
  }

  ngOnDestroy(): void {
    this._sifraSub.unsubscribe();
  }
}
