import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

/** Moduli */
import { BankaRoutingModule } from './banka-routing.module';
import { CoreSharedModule } from 'libs/core/shared/src';
import { CoreSharedSubformsModule } from 'libs/core/shared-subforms/src';

/** Components */
import { BankaAutocompleteComponent } from './ui/banka-autocomplete/banka-autocomplete.component';
import { IzvodFileuploadComponent } from './izvod/ui/izvod-fileupload/izvod-fileupload.component';
import { ObradaIzvodaPageComponent } from './izvod/obrada-izvoda-page/obrada-izvoda-page.component';
import { IzvodTableComponent } from './izvod/izvod-table/izvod-table.component';
import { KategorijaIsplateDropdownComponent } from './izvod/ui/kategorija-isplate-dropdown/kategorija-isplate-dropdown.component';
import { IzvodFilterComponent } from './izvod/izvod-filter/izvod-filter.component';
import { BankaDropdownComponent } from './ui/banka-dropdown/banka-dropdown.component';
import { IsplatePageComponent } from './izvod/isplate-page/isplate-page.component';
import { UplatePageComponent } from './izvod/uplate-page/uplate-page.component';
import { TekuciRacunTableComponent } from './tekuci-racun/tekuci-racun-table/tekuci-racun-table.component';
import { TekuciRacunFormComponent } from './tekuci-racun/tekuci-racun-form/tekuci-racun-form/tekuci-racun-form.component';
import { IzvorXmlIzvodaDropdownComponent } from './izvod/ui/izvor-xml-izvoda-dropdown/izvor-xml-izvoda-dropdown.component';
import { IzvodFajlIzvorDropdownComponent } from './izvod/ui/izvod-fajl-izvor-dropdown/izvod-fajl-izvor-dropdown.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DodajIzvodFormComponent } from './izvod/ui/dodaj-izvod-form/dodaj-izvod-form.component';

@NgModule({
  imports: [BankaRoutingModule, CoreSharedModule, CoreSharedSubformsModule, SelectButtonModule],
  declarations: [
    BankaAutocompleteComponent,
    IzvodTableComponent,
    IzvodFileuploadComponent,
    ObradaIzvodaPageComponent,
    KategorijaIsplateDropdownComponent,
    IzvodFilterComponent,
    BankaDropdownComponent,
    IsplatePageComponent,
    UplatePageComponent,
    TekuciRacunTableComponent,
    TekuciRacunFormComponent,
    IzvorXmlIzvodaDropdownComponent,
    IzvodFajlIzvorDropdownComponent,
    DodajIzvodFormComponent,
  ],
  exports: [TekuciRacunTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModuliBankaModule {}
