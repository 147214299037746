import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  Pdv39Form,
  PdvEvidencijaPojedinacnaService,
} from '../pdv-evidencija-pojedinacna.service';
import { PdvEvidencijaPojedinacnaForm39Service } from '../pdv-evidencija-pojedinacna39-form.service';
import { ActivatedRoute } from '@angular/router';
import {
  CreatePdvEvidencijaPojedinacna39Command,
  IVezaniRacunDto,
  PdvEvidencijaOsnovOdabiraDokumenta,
  PdvEvidencijaTipDokumenta,
  PdvEvidencijaTipPovezaneFakture,
  PdvEvidencijaTipPovezanogInternog,
  PdvEvidencijeClient,
  SefKorigujPdvEvidencijaPojedinacna39Command,
  TipRacuna,
  UpdatePdvEvidencijaPojedinacna39Command,
  VrstaRacuna,
} from '@kodit/core/data-api';
import {
  FormHelper,
  VezaniRacunFormService,
} from '@kodit/core/form-definitions';
import { ConfirmationService } from 'primeng/api';
import {
  AlertService,
  LocationService,
  SharedService,
} from '@kodit/core/services';
import { FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'kodit-pdv-evidencija-pojedinacna39-form',
  templateUrl: './pdv-evidencija-pojedinacna39-form.component.html',
  styleUrls: ['./pdv-evidencija-pojedinacna39-form.component.scss'],
})
export class PdvEvidencijaPojedinacna39FormComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  form!: Pdv39Form;
  useOnlyTipove: TipRacuna[] | undefined;
  shouldDisableForm: boolean = false;
  tipDokumenta = PdvEvidencijaTipDokumenta;
  tipPovezaneFakture = PdvEvidencijaTipPovezaneFakture;
  tipPovezanogInternog = PdvEvidencijaTipPovezanogInternog;
  promet = VrstaRacuna;
  osnovOdabira = PdvEvidencijaOsnovOdabiraDokumenta;
  shouldLabelBeAvans: boolean = false;
  shouldDisplayNabavkaBN = true;
  isKoriguj: boolean;

  /** I/O */
  @ViewChild('avansniVanSefaInput') avansniVanSefaInput!: ElementRef;

  constructor(
    private _fs: PdvEvidencijaPojedinacnaForm39Service,
    private _route: ActivatedRoute,
    private _formHelper: FormHelper,
    public _pdvPojedinacnaService: PdvEvidencijaPojedinacnaService,
    private _confirmationService: ConfirmationService,
    private _locationService: LocationService,
    private _sharedService: SharedService,
    private _client: PdvEvidencijeClient,
    private _alert: AlertService,
    private _vezaniRacunFS: VezaniRacunFormService
  ) {
    this.isKoriguj = false;
  }

  ngOnInit(): void {
    this.shouldDisableForm = this._route.snapshot.data['isPregledKorigovane'];
    this.isKoriguj = this._route.snapshot.data['isKoriguj'];
    this.form = this._fs.getForm(
      this._route.snapshot.data['evidencija'],
      this.isKoriguj
    );

    this.loadOsnovOdabiraProps();

    this._subs.add(
      this.form?.controls.tipPovezaneFakture?.valueChanges.subscribe(
        (value) => {
          if (value === this.tipPovezaneFakture.VREMENSKI_PERIOD) {
            this.form?.controls.vremenskiPeriodPocetak?.addValidators(
              Validators.required
            );
            this.form?.controls.vremenskiPeriodZavrsetak?.addValidators(
              Validators.required
            );

            return;
          }
          this.form?.controls.vremenskiPeriodPocetak?.patchValue(null);
          this.form?.controls.vremenskiPeriodPocetak?.clearValidators();
          this.form?.controls.vremenskiPeriodZavrsetak?.patchValue(null);
          this.form?.controls.vremenskiPeriodZavrsetak?.clearValidators();
        }
      )
    );

    this._subs.add(
      this.form!.controls.osnovica20?.valueChanges.subscribe((res) => {
        this.form!.controls.obracunatPdv20?.patchValue(res! * 0.2);
      })
    );

    this._subs.add(
      this.form!.controls.osnovica10?.valueChanges.subscribe((res) => {
        this.form!.controls.obracunatPdv10?.patchValue(res! * 0.1);
      })
    );

    this._avansLabelsInit();
  }

  private loadOsnovOdabiraProps() {
    this.shouldLabelBeAvans =
      this.form?.value.osnovOdabiraDokumenta ===
      PdvEvidencijaOsnovOdabiraDokumenta.AVANS;

    this.shouldDisplayNabavkaBN =
      this.form?.value.osnovOdabiraDokumenta ===
      PdvEvidencijaOsnovOdabiraDokumenta.PROMET;
  }

  handleSave() {
    // check is it valid
    if (!this.form!.valid) {
      this._formHelper.invalidateForm(this.form!);
      this._pdvPojedinacnaService.setFormSaveClicked = true;
      return;
    }

    // check at least one group(opis, osnovica, obracunatiPDV) is present
    if (!this._fs.areOsnovicaGroupsValid()) {
      this._alert.addWarnMsg(
        "Potrebno je popuniti 'Opis', 'Osnovica' i 'Obračunati PDV' u barem jednoj sekciji."
      );
      return;
    }

    if (this.isKoriguj) {
      this._sharedService.displayLoadingScreen(
        true,
        'Korigovanje zbirne pdv evidencije...'
      );
      this._subs.add(
        this._client
          .sefKorigujPdvEvidencijaPojedinacna39(
            this.form!.value.id!,
            this.form!.value as SefKorigujPdvEvidencijaPojedinacna39Command
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    } else if (this._fs.jeEdit) {
      this._sharedService.displayLoadingScreen(
        true,
        'Ažuriranje pojedinačne pdv evidencije...'
      );
      // privremeno resenje dok ne uvedemo automatsko presabiranje
      this.form?.controls.obracunatPdv?.patchValue(
        this._getUkupnoObracunatPdv()
      );
      this._subs.add(
        this._client
          .updatePojedinacna39(
            this.form!.value.id!,
            this.form!.value as UpdatePdvEvidencijaPojedinacna39Command
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    } else {
      this._sharedService.displayLoadingScreen(
        true,
        'Kreiranje pojedinačne pdv evidencija...'
      );
      // privremeno resenje dok ne uvedemo automatsko presabiranje
      this.form?.controls.obracunatPdv?.patchValue(
        this._getUkupnoObracunatPdv()
      );
      this._subs.add(
        this._client
          .createPojedinacna39(
            this.form!.value as CreatePdvEvidencijaPojedinacna39Command
          )
          .subscribe((res) => {
            if (res.succeeded) {
              this._alert.addSuccessMsg(res.data!);
              this._locationService.routeBack();
            }
            this._alert.addFailedMsg(res.messages![0]);
          })
      );
    }
  }

  handleCancel() {
    this._confirmationService.confirm({
      message: 'Ukoliko otkažete sav nesnimljen sadržaj će biti izgubljen.',
      acceptLabel: 'Otkaži',
      rejectLabel: 'Ostani na stranici',
      header: 'Potvrdite otkazivanje',
      icon: 'far fa-check-circle',
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this._locationService.routeToCancelPdvEvidencija();
      },
    });
  }

  handleRouteBack() {
    this._locationService.routeBack();
  }

  handleAvansniVanSefaClick() {
    let value = this.avansniVanSefaInput.nativeElement.value;

    if (value.length === 0) {
      this._alert.addWarnMsg('Broj dokumenta ne sme biti prazan');
      return;
    }

    (this.form!.controls.povezaniVanSefa as FormArray).push(
      new FormControl(value, { validators: [Validators.required] })
    );
    this.avansniVanSefaInput.nativeElement.value = '';
  }

  handleVezaniRacunChange(vezaniRacun: IVezaniRacunDto) {
    (this.form!.controls.povezaniRacuni as FormArray).push(
      this._vezaniRacunFS.getFormGroup(vezaniRacun)
    );
  }

  onPrometChange(event: any) {
    this._pdvPojedinacnaService.setIsIsporuka =
      event.value === VrstaRacuna.IZLAZNI;

    this.shouldLabelBeAvans = false;

    this._fs.resetOnIsporukaNabavkaChange(
      this._pdvPojedinacnaService.getIsIsporuka
    );

    this._fs.resetVezaneRacune();
  }

  onTipDokumentaChange(event: any) {
    this._pdvPojedinacnaService.setTipDokumentaSubject = event.value;

    this._checkShouldDisableAvansLabelIsporuka(event, false);

    if (event.value !== PdvEvidencijaTipDokumenta.AVANSNA_FAKTURA) {
      this._fs.resetDatumAvansa();
      this._fs.resetDokumentAndStranka();
    }

    this._fs.resetVezaneRacune();

    if(this._pdvPojedinacnaService.getIsIsporuka){
      this._fs.resetSProps();
      return;
    }
    this._fs.resetAEProps();
  }

  onTipPovezanogDokumentaChange(event: any) {
    this._pdvPojedinacnaService.setIsAvansni =
      event.value === PdvEvidencijaTipPovezaneFakture.AVANSNA_FAKTURA;

    //this._fs.resetSProps();

    //this._fs.resetVezaneRacune();

    this._checkShouldDisableAvansLabelIsporuka(event, true);
  }

  private _checkShouldDisableAvansLabelIsporuka(
    event: any,
    isTipPovezanogChange: boolean
  ) {
    if (isTipPovezanogChange) {
      this.shouldLabelBeAvans =
        event.value === this.tipPovezaneFakture.AVANSNA_FAKTURA &&
        this.form?.value.tip === this.tipDokumenta.DOKUMENT_O_SMANJENJU;
      return;
    }

    this.shouldLabelBeAvans =
      event.value === this.tipDokumenta.AVANSNA_FAKTURA ||
      (event.value === this.tipDokumenta.DOKUMENT_O_SMANJENJU &&
        this.form?.value.tipPovezaneFakture ===
          this.tipPovezaneFakture.AVANSNA_FAKTURA);
  }

  private _checkShouldDisableAvansLabelNabavka(
    event: any,
    isTipPovezanogInternogChange: boolean
  ) {
    // event je tipPovezanogInternog
    if (isTipPovezanogInternogChange) {
      this.shouldLabelBeAvans =
        this.form!.value.osnovOdabiraDokumenta === this.osnovOdabira.SMANJENJE &&
        event.value === this.tipPovezanogInternog.INTERNI_RACUN_ZA_AVANS;

      return;
    }

    // event je osnov
    this.shouldLabelBeAvans =
      event.value === this.osnovOdabira.AVANS ||
      (event.value === this.osnovOdabira.SMANJENJE &&
        this.form?.value.tipPovezanogInternog ===
          this.tipPovezanogInternog.INTERNI_RACUN_ZA_AVANS);
  }

  private _avansLabelsInit(){
    if(this._pdvPojedinacnaService.getIsIsporuka){
      this._avansLabelsInitIsporuka();
      return;
    }

    this._avansLabelsInitNabavka();
  }

  private _avansLabelsInitIsporuka(){
    this.shouldLabelBeAvans =
      this._pdvPojedinacnaService.getTipDokumentaSubject ===
        this.tipDokumenta.AVANSNA_FAKTURA ||
      (this._pdvPojedinacnaService.getTipDokumentaSubject === this.tipDokumenta.DOKUMENT_O_SMANJENJU &&
        this.form?.value.tipPovezaneFakture ===
          this.tipPovezaneFakture.AVANSNA_FAKTURA);
  }

  private _avansLabelsInitNabavka(){
    this.shouldLabelBeAvans =
      this.form?.value.osnovOdabiraDokumenta === this.osnovOdabira.AVANS ||
      (this.form?.value.osnovOdabiraDokumenta === this.osnovOdabira.SMANJENJE &&
        this.form?.value.tipPovezanogInternog ===
          this.tipPovezanogInternog.INTERNI_RACUN_ZA_AVANS);
  }

  onOsnovaOdabiraChange(event: any) {
    this._pdvPojedinacnaService.setOsnovOdabira = event.value;

    this._checkShouldDisableAvansLabelNabavka(event, false);

    this.shouldDisplayNabavkaBN =
      event.value === PdvEvidencijaOsnovOdabiraDokumenta.PROMET;
    if (!this.shouldDisplayNabavkaBN) {
      this._fs.resetBNProps();
    }

    if (!this.shouldLabelBeAvans) {
      this._fs.resetDatumAvansa();
    }
  }

  onTipPovezanogInternogChange(event: any) {
    this._pdvPojedinacnaService.setIsInterniZaAvans =
      event.value === PdvEvidencijaTipPovezanogInternog.INTERNI_RACUN_ZA_AVANS;

    this._fs.resetAEProps();

    this._fs.resetVezaneRacune();

    this._checkShouldDisableAvansLabelNabavka(event, true);
  }

  private _getUkupnoObracunatPdv() {
    return (
      (this.form?.value.obracunatPdv10 ?? 0) +
      (this.form?.value.obracunatPdv20 ?? 0) +
      (this.form?.value.obracunatPdvAE10 ?? 0) +
      (this.form?.value.obracunatPdvAE20 ?? 0) +
      (this.form?.value.obracunatPdvAE10Obj ?? 0) +
      (this.form?.value.obracunatPdvAE20Obj ?? 0) +
      (this.form?.value.obracunatPdvAE10BN ?? 0) +
      (this.form?.value.obracunatPdvAE20BN ?? 0) +
      (this.form?.value.obracunatPdvAE10ObjBN ?? 0) +
      (this.form?.value.obracunatPdvAE20ObjBN ?? 0)
    );
  }


  ngOnDestroy(): void {
    this._fs.resetForm();
    this._pdvPojedinacnaService.setFormSaveClicked = false;
    this._subs.unsubscribe();
  }
}
